import BaseService from './BaseService';

export default class AhService extends BaseService{


    constructor(){
        super();
    }

	getSrateData() {
		return this.getAxios().get('/api/ah/application')
    }

    save(srate_record){       
        return this.getAxios().post('/api/ah/application', srate_record)
    }

    delete(id){       
        return this.getAxios().delete('/api/ah/application/'+id)
    }
}
