<template>
    <Toast/>
	<div class="grid">

  <div class="col-12">
			<div class="card">
                

				<h5>Application Master</h5>

               <DataTable ref="dt" :value="products"  class="p-datatable-gridlines"    :loading="loading2"  responsiveLayout="scroll" >
					
                    <template #header>						 
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <div>
                                    <Button :disabled='new_disabled' label="New" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
                                    <Button label="Delete" icon="pi pi-trash" class="p-button-danger" :disabled="true" />
                            </div> 
                            <div class="mb-2">
                                <Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
                            </div>
                        </div> 
					</template>
					
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading  data. Please wait.
                    </template>

                    <Column field="B4" header="Action" style="min-width:8rem">
						<template #body="{data}">
							<Button v-if="productIdEdit!=data.A4" icon="pi pi-pencil" class="p-button-rounded p-button-text  p-button-success mr-2" @click="editProduct(data.A4)" />
                            <Button v-if="productIdEdit==data.A4" icon="pi pi-check" class="p-button-rounded p-button-text mr-2" @click="saveProduct"/>
                            <Button v-if="productIdEdit==data.A4" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text mr-2"  @click="cancelEdit()"/>
                            <Button v-if="productIdEdit!=data.A4" icon="pi pi-trash" class="p-button-rounded p-button-text  p-button-warning" @click="confirmDeleteProduct(data.A4)" />
						</template>
					</Column>
                    <Column field="B4" header="Unique code for application" :style="{'min-width':'7rem','color':'red'}" frozen>
                        <template #body="{data}">
							<div class="formgrid grid p-fluid"  v-if="productIdEdit==data.A4">
                               <div class="col-12">
                                   <InputText v-model="data.B4"  ></InputText>
                                </div>
                            </div>
                            <div v-else>
                                {{data.B4}}
                            </div>
						</template>
                    </Column>
                    <Column field="C4" header="Sample Id" :style="{'min-width':'6rem','color':'red'}">
                        <template #body="{data}">
							<div class="formgrid grid p-fluid"  v-if="productIdEdit==data.A4">
                               <div class="col-12">
                                   <Dropdown v-model="data.samplleId" v-on:change="onSrateChange" :options="srates" optionLabel="name" placeholder="Select" />                                  
                                </div>
                            </div>
                            <div v-else>
                                {{getSrateNameById(data.srateid)}}
                            </div>
						</template>
                    </Column>
                    <Column field="D4" header="KM a" :style="{'min-width':'5rem'}">
                        <template #body="{data}">
							<div>
                                {{parseFloat(data.V4).toFixed(2)}}
                            </div>
						</template>
                    </Column>
                    <Column field="E4" header="KM b" :style="{'min-width':'5rem'}">
                        <template #body="{data}">							
                            <div>
                                {{parseFloat(data.W4).toFixed(2)}}
                            </div>
						</template>
                    </Column>
                    <Column field="F4" header="KM s" :style="{'min-width':'5rem'}">
                        <template #body="{data}">							
                            <div>
                                {{parseFloat(data.AD4).toFixed(2)}}
                            </div>
						</template>
                    </Column>
                    <Column field="G4" header="Rg corr" :style="{'min-width':'5rem'}">
                        <template #body="{data}">							
                            <div>
                                {{parseFloat(data.P4).toFixed(2)}}
                            </div>
						</template>
                    </Column>
                    <Column field="H4" header="Density" :style="{'min-width':'5rem'}">
                        <template #body="{data}">							
                            <div>
                                {{parseFloat(data.F4).toFixed(2)}}
                            </div>
						</template>
                    </Column>
                    <Column field="I4" header="Weight [g]" :style="{'min-width':'6rem',color:'red'}">
                        <template #body="{data}">
							<div class="formgrid grid p-fluid"  v-if="productIdEdit==data.A4">
                               <div class="col-12">
                                   <InputNumber v-model="data.weight"  v-on:input="onWeightChange" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2"></InputNumber>
                                </div>
                            </div>
                            <div v-else>
                                {{parseFloat(data.weight).toFixed(2)}}
                            </div>
						</template>
                    </Column>
                    <Column field="J4" header="Area [cm2]" :style="{'max-width':'5rem'}">
                        <template #body="{data}">							
                            <div>
                                {{parseFloat(data.area).toFixed(2)}}
                            </div>
						</template>
                    </Column>


                    <Column field="K4" header="Coating thickness [µm]" :style="{'max-width':'5rem'}" frozen>
                        <template #body="{data}">
							{{parseFloat(data.C_TH).toFixed(2)}}
						</template>
                    </Column>
                    <Column field="L4" header="Spread rate [m2/L]" :style="{'max-width':'5rem'}" >
                        <template #body="{data}">
							{{parseFloat(data.S_R).toFixed(2)}}
						</template>
                    </Column>
                    <Column field="M4" header="R∞" :style="{'min-width':'5rem'}" >
                        <template #body="{data}">
							{{parseFloat(data.R_IF).toFixed(2)}}%
						</template>
                    </Column>
                    <Column field="N4" header="L*∞" :style="{'min-width':'5rem'}" >
                        <template #body="{data}">
							{{parseFloat(data.L_IF).toFixed(2)}}
						</template>
                    </Column>
                    <Column field="O4" header="Link to scanned image" :style="{'max-width':'6rem' ,color:'blue'}">
                        <template #body="{data}">
							{{parseFloat(data.O4).toFixed(2)}}
						</template>
                    </Column>
                   
                </DataTable>
                
			</div>
		</div>
		
	</div>

                <Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="product">Are you sure you want to delete <b>{{product.name}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProduct" />
					</template>
				</Dialog>
</template>

<script>
    import SrateService from "../../service/SrateService";
    import AhService from "../../service/AHService";

	export default {
		data() {
			return {
				customer2: null,
				loading1: true,
				loading2: true,
                deleteProductDialog: false,
                productToBeDeleted: -1,
                product: {},
                submitted: false,
                new_disabled: true,
                productIdEdit:-1,
                dropdownValues: [
					{name: 'New York', code: 'NY'},
					{name: 'Rome', code: 'RM'},
					{name: 'London', code: 'LDN'},
					{name: 'Istanbul', code: 'IST'},
					{name: 'Paris', code: 'PRS'}
				],
                //products:[{"'[srate.xlsx]SETTINGS'!B2":"Spread Rate Calculator v1.0 Oct 2013","'[srate.xlsx]SETTINGS'!B4":"Setting","'[srate.xlsx]SETTINGS'!B5":"Correct for Substrate Specular Reflectance","'[srate.xlsx]SETTINGS'!B6":"Contrast Ratio at Complete Hiding","'[srate.xlsx]SETTINGS'!B8":"Spread Rate Target, m2/liter","'[srate.xlsx]SETTINGS'!B9":"Substrate Reflectance at Spread Rate Target","'[srate.xlsx]SETTINGS'!C4":"Value","'[srate.xlsx]SETTINGS'!C5":0.96,"'[srate.xlsx]SETTINGS'!C6":0.98,"'[srate.xlsx]SETTINGS'!C8":20,"'[srate.xlsx]SETTINGS'!C9":0.8,"'[srate.xlsx]SETTINGS'!D4":"Comments","'[srate.xlsx]SETTINGS'!D5":"Set Value = 1.00 for 'Un-Corrected' value.  For glossy opacity charts, use a value of 0.96 to correct for substrate specular reflectance..","A4":1,"AA4":"4.356282894028471","AB4":"21.32465134099617","AC4":"4.356282894028471","AD4":"0.0928962138579029","AE4":"0.928962138579029","AF4":"0.912189203458832","AG4":"1.0042265003575777","AH4":"0.09203729689874561","AI4":"-1.9919350863032175","AJ4":"1.1278466934799478","AK4":"0.9047401292784374","AL4":"0.9047401292784374","AM4":"0.8866453266928687","AN4":"0.845625","AO4":"1.0042265003575777","AP4":"0.09203729689874561","AQ4":"8.089293300246764","AR4":"#NUM!","AS4":"#VALUE!","AT4":"10.436398091925849","AV4":"10.436398091925849","AW4":"112.34470877241246","AX4":"8.901175773447388","AY4":"11.234470877241247","AZ4":"0.912189203458832","B4":"LA21083_20","BA4":"0.0042265003575776205","C4":"BOSS","D4":2.61,"E4":386,"F4":1.4419,"G4":100,"H4":0.8118,"I4":0.8873,"J4":0.8026,"K4":"0.912189203458832","L4":"112.34470877241246","M4":"8.901175773447388","N4":"4.689408441007062","O4":"0.0928962138579029","P4":"0.845625","Q4":"0.98","S4":"0.9045418685901048","T4":"46.89408441007062","U4":"4.689408441007062","V4":"1.0042265003575777","W4":"0.09203729689874561","X4":"4.13694684062785","Y4":"#NUM!","Z4":"#VALUE!"}]
                products:[],
                srates:[]
            }
		},
		ahService: null,
        srateService:null,
		created() {
            this.srateService = new SrateService();
            this.ahService = new AhService();
		},
		mounted() {
			
			this.srateService.getSrateData().then((data) => {
                    let resp = data.data;
                    let key_val=[];
                    for (let i = 0; i < resp.length; i++) {
                        let row={"name":resp[i]["B4"],"code":resp[i]["A4"],data:resp[i]};
                        key_val.push(row);
                    }
                    this.srates=key_val;
                    console.log(this.srates);
                    this.ahService.getSrateData().then((data)=>{
                        this.products = data.data
                        this.loading2 = false;
                        this.new_disabled=false;
                    })
                    
                });
			
		},
		methods: {
			
			formatCurrency(value) {
				return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
			},
			openNew() {
                this.productIdEdit=((new Date()).getTime());
                this.product = {"A4":this.productIdEdit,"B4":"Application","V4":0.0,"W4":0.0,"AD4":0.0,"P4":0.0,"F4":0.0,"area":32*29.6,
                "C_TH":0.0,weight:0.0,"srateid":0}
                this.products.push(this.product);                
                this.submitted = true;
                this.new_disabled = true;
                //this.productDialog = true;
            },
            hideDialog() {
                this.submitted = false;
            },
            editProduct(id){
                this.productIdEdit=id;
                this.submitted = false;
                this.new_disabled = true;
                this.product=this.findIndexById(id);
            },
            saveProduct() {
                this.submitted = true;
                this.new_disabled = true;
                this.loading2 = true;
                console.log(this.product);
                if (this.product.A4) {                    
                    this.ahService.save(this.product)
                                .then((res) => {
                                    console.log(res);
                                    this.ahService.getSrateData().then((data) => {
                                        this.products = data.data;
                                        this.$toast.add({severity:'success', summary: 'Successful', detail: 'Record Saved!', life: 3000});
                                    });
                                }).catch(error => {
                                    console.log(error);
                                    this.$toast.add({severity:'error', summary: 'Error', detail: error.message, life: 3000});
                                }).finally(()=> {
                                    this.loading2 = false;
                                    this.submitted = false;
                                    this.product = {};
                                    this.productIdEdit=-1;
                                    this.new_disabled = false;
                                });        
                        
                }
            },
            confirmDeleteProduct(product) {
			this.productToBeDeleted = product;
			this.deleteProductDialog = true;
            },
            deleteSelectedProduct() {
                this.deleteProductDialog = false;      
                this.loading2 = true;         
                this.ahService.delete(this.productToBeDeleted)
                                .then((res) => {
                                    console.log(res);
                                        this.productToBeDeleted = -1;
                                        this.ahService.getSrateData().then((data) => {
                                        this.products = data.data;
                                        this.$toast.add({severity:'success', summary: 'Successful', detail: 'Record Deleted ', life: 3000});
                                    });
                                }).catch(error => {
                                    console.log(error);
                                    this.$toast.add({severity:'error', summary: 'Error', detail: error.message, life: 3000});
                                }).finally(()=> {
                                    this.loading2 = false;
                                    this.submitted = false;
                                    this.product = {};
                                    this.productIdEdit=-1;
                                    this.productToBeDeleted = -1;
                                });        
            },
            findIndexById(id) {
                let index = -1;
                for (let i = 0; i < this.products.length; i++) {
                    if (this.products[i]["A4"] === id) {
                        index = i;
                        break;
                    }
                }
                return this.products[index];
            },
            cancelEdit(){
                 this.productIdEdit=null;
                  this.new_disabled=false;
                 if(this.submitted){
                     this.products.pop();
                     this.submitted=false;
                 }
            },
            exportCSV() {
			this.$refs.dt.exportCSV();
		},
        onWeightChange(e){
            let length=this.products.length;
            let current_product=this.products[length-1];
            current_product["weight"] = e.value;
            this.calculate_coating_thickness(current_product);
        },
        onSrateChange(e){
            let data=e.value["data"];
            let length=this.products.length;
            let current_product=this.products[length-1];
            console.log(data);
            current_product["V4"]=data["V4"];
            current_product["W4"]=data["W4"];
            current_product["P4"]=data["P4"];
            current_product["F4"]=data["F4"];
            current_product["AD4"]=data["AD4"];
            current_product["srateid"]=data["A4"];
            let R_IF=(data["K4"]);
            current_product["R_IF"] = 100*(R_IF);
            if(R_IF > 0.008856){
                current_product["L_IF"] = (Math.pow(R_IF,1/3)*116)-16;
            }else{
                current_product["L_IF"] = (((7.787*R_IF+16)/116)*116)-16;
            }            
            this.calculate_coating_thickness(current_product);
        },
        calculate_coating_thickness(c_product){
            let weight=c_product["weight"];
            let area=c_product["area"];
            let density=c_product["F4"];
            if(weight>0 && density>0){
               
                c_product["C_TH"]= (1000*weight*10/(density*area));
                c_product["S_R"]= (1000/c_product["C_TH"]);
            }else{
                c_product["C_TH"]= 0.0;
                c_product["S_R"] = "";
            }
            
        },
        getSrateNameById(id){
            let index = -1;
            for (let i = 0; i < this.srates.length; i++) {
                if (this.srates[i]["code"] === id) {
                    index = i;
                    break;
                }
            }
            return index<0?"Resp. Srate Deleted":this.srates[index]["name"];
        }

		}
	}
</script>

<style scoped lang="scss">
	.customer-badge {
		border-radius: 2px;
		padding: .25em .5rem;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		letter-spacing: .3px;

		&.status-qualified {
			background: #C8E6C9;
			color: #256029;
		}

		&.status-unqualified {
			background: #FFCDD2;
			color: #C63737;
		}

		&.status-negotiation {
			background: #FEEDAF;
			color: #8A5340;
		}

		&.status-new {
			background: #B3E5FC;
			color: #23547B;
		}

		&.status-renewal {
			background: #ECCFFF;
			color: #694382;
		}

		&.status-proposal {
			background: #FFD8B2;
			color: #805B36;
		}
	}

	.product-badge {
		border-radius: 2px;
		padding: .25em .5rem;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		letter-spacing: .3px;

		&.status-instock {
			background: #C8E6C9;
			color: #256029;
		}

		&.status-outofstock {
			background: #FFCDD2;
			color: #C63737;
		}

		&.status-lowstock {
			background: #FEEDAF;
			color: #8A5340;
		}
	}

	.order-badge {
		border-radius: 2px;
		padding: .25em .5rem;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		letter-spacing: .3px;

		&.order-delivered {
			background: #C8E6C9;
			color: #256029;
		}

		&.order-cancelled {
			background: #FFCDD2;
			color: #C63737;
		}

		&.order-pending {
			background: #FEEDAF;
			color: #8A5340;
		}

		&.order-returned {
			background: #ECCFFF;
			color: #694382;
		}
	}
</style>