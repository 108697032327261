import BaseService from './BaseService';

export default class SrateService extends BaseService{


    constructor(){
        super();
    }

	getSrateData() {
		return this.getAxios().get('/api/srate')
    }
  getEventListData(table_name) {
    return this.getAxios().get('/api/srate/EventListCheck/'+ table_name)
    }  
      
  getSrateTemplateData() {
      return this.getAxios().get('/api/srate/TempEmails')
      }  
    getById(id){       
        return this.getAxios().get('/api/srate/'+id)
    }
    sendRosterById(id){
        return this.getAxios().get('/api/srate/roster/'+id)
    }
    save(srate_record){       
        return this.getAxios().post('/api/srate', srate_record)
    }

    delete(id){      
        return this.getAxios().delete('/api/srate/'+id)
    }

    getSrateTemplate(id) {
		return this.getAxios().get('/api/srate/Temp/'+id)
    }
    sendEmailTemp(id,srate_record) {
		return this.getAxios().post('/api/srate/sendEmailTemp1/'+id, srate_record)
    }
    sendEmailReminder(id) {
		return this.getAxios().post('/api/srate/sendEmailReminder1/'+id)
    }
    createEventList(srate_record) {
      return this.getAxios().post('/api/srate/CreateEventList' ,srate_record)
    }
    
}
